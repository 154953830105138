
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="用户ID" prop="userNo">
                <a-input v-model="queryParam.userNo" placeholder="请输入用户ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="用户昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>
              </a-form-item>
            </a-col>
              <a-col :md="5" :sm="24">
                <a-form-item label="用户身份" prop="userIdentity">
                  <a-select placeholder="请选择用户身份" v-model="queryParam.userIdentity" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.guild_user_identity" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="24">
                <a-form-item label="职位" prop="position">
                  <a-select placeholder="请选择职位" v-model="queryParam.position" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.guild_user_position" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            <a-col :md="4" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:guild:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:guild:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->

<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:guild:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:guild:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :userIdentityOptions="dict.type.guild_user_identity"
        :positionOptions="dict.type.guild_user_position"
        @ok="getList"
      />
      <cancel-contract-form
        ref="cancelContractForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

            <span slot="avatar" slot-scope="text, record">
            <img style="width: 40px;height: 40px;margin-top: 5px;margin-right: 5px;" v-if="record.avatar"
                 v-for="(guildLogo,imgIndex) in record.avatar.split(',')"
                 :src="guildLogo"
                 preview="图片" />
        </span>


        <span slot="userIdentity" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_user_identity']" :value="record.userIdentity"/>
        </span>
        <span slot="position" slot-scope="text, record">
          <dict-tag :options="dict.type['guild_user_position']" :value="record.position"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['biz:guild:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:guild:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--                 <a v-if="record.isPromoter!=1" @click="handleSetPromoter(record)" v-hasPermi="['biz:guild:remove']">-->
<!--                      <a-icon type="setting" />设为推广员-->
<!--                    </a>-->

                  <a v-if="record.userIdentity == 1"  @click="$refs.cancelContractForm.handleUpdate(record, undefined)" v-hasPermi="['biz:guild:edit']">
                      <a-icon type="edit" />解约
                  </a>

<!--          <a-divider type="vertical" v-hasPermi="['biz:guild:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:guild:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGuild,listGuild, delGuild ,setPromoter } from '@/api/biz/guildUser'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import CancelContractForm from '@/views/biz/guildUser/modules/CancelContractForm.vue'

export default {
  name: 'Guild',
  components: {
    CancelContractForm,
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['guild_user_identity', 'guild_user_position'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        guildId: null,
        userId: null,
        userIdentity: null,
        position: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "avatar"}
        },
        {
          title: '用户ID',
          dataIndex: 'userNo',
          ellipsis: true,
          align: 'center',width: 100
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',width: 50,
          customRender: function (value) {
            if (value==1){
              return "男"
            }else if(value==2){
              return "女"
            }else {
              return "保密"
            }
          }
        },
        {
          title: '用户身份',
          dataIndex: 'userIdentity',
          scopedSlots: { customRender: 'userIdentity' },
          ellipsis: true,
          align: 'center' ,width:80
        },
        {
          title: '签约开始时间',
          dataIndex: 'signDateStart',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签约结束时间',
          dataIndex: 'signDateEnd',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '职位',
          dataIndex: 'position',
          scopedSlots: { customRender: 'position' },
          ellipsis: true,
          align: 'center',width: 80
        },
        // {
        //   title: '推广员',
        //   dataIndex: 'isPromoter',
        //   ellipsis: true,
        //   align: 'center',width: 60,
        //   customRender: function (value) {
        //     if (value==1){
        //       return "是"
        //     }else if(value==0){
        //       return "否"
        //     }else {
        //       return "否"
        //     }
        //   }
        // },
        // {
        //   title: '创建人',
        //   dataIndex: 'creator',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '加入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
        width:180,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询公会用户列表 */
    getList () {
      this.loading = true
     pageGuild(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        guildId: undefined,
        userId: undefined,
        userIdentity: undefined,
        position: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    //设置推广人
    handleSetPromoter (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认设为推广员?',
        content: '当前选中编号为 ' + row.nickname + ' 的数据',
        onOk () {
          let data ={
            id:row.id,
            isPromoter:1
          }
          return setPromoter(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGuild(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/guildUser/export', {
            ...that.queryParam
          }, `公会用户_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
